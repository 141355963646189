var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:_vm.infoModal.id,attrs:{"id":_vm.infoModal.id,"hide-footer":"","size":"lg","ok-title":"","cancel-title":"Cancelar","centered":"","no-close-on-backdrop":""},on:{"ok":_vm.createTransactios},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('span',[_c('h1',[_c('strong',[_vm._v("Paga con Nequi")])])])]},proxy:true}])},[_c('b-form',[_c('b-form-group',{attrs:{"label":"Número celular de tu cuenta Nequi","label-for":"v-celular"}},[_c('validation-provider',{attrs:{"name":"celular|min:13","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-phone-number-input',{attrs:{"default-country-code":"CO","translations":{
                        countrySelectorLabel: 'Codigo pais',
                        countrySelectorError: 'Choisir un pays',
                        phoneNumberLabel: 'Numéro de Celular',

                        example: 'Ejemplo :',
                    }},on:{"update":function($event){_vm.results = $event}},model:{value:(_vm.transactions.payment_method.phone_number),callback:function ($$v) {_vm.$set(_vm.transactions.payment_method, "phone_number", $$v)},expression:"transactions.payment_method.phone_number"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" campo requerido ")])]}}])})],1),_c('b-form-group',[_c('span',[_vm._v("Recibiras una "),_c('strong',[_vm._v("notificación push")]),_vm._v(" en tu celular.")])]),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","name":"checkbox-1"}},[_vm._v(" Acepto haber leído los "),_c('b-link',{attrs:{"href":_vm.permalink,"target":"_blank"}},[_vm._v("términos y condiciones y la política de privacidad")]),_vm._v(" para hacer este pago. ")],1)],1),_c('b-form-group',[_c('div',{staticClass:"demo-inline-spacing"},[_c('b-button',{attrs:{"variant":"outline-danger","block":""},on:{"click":_vm.hideModal}},[_vm._v("Cancelar")]),_c('b-button',{attrs:{"variant":"primary","block":""},on:{"click":_vm.createTransactiosCrmred}},[_vm._v("Continuar con tu pago de $"+_vm._s(_vm.price_banck)+" COP")])],1)])],1)],1),_c('modalWithTimer',{ref:"modalWithTimer"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }